@import url("https://fonts.googleapis.com/css2?family=Sora:wght@300;400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --font-sora: "Sora", sans-serif;
  --font-code: "Source Code Pro", monospace;
  --font-grotesk: "Space Grotesk", sans-serif;
  color-scheme: dark;
}

* {
  scroll-behavior: smooth
}

@layer base {
  body {
    @apply font-sans bg-n-8 text-n-1 text-base;
  }
}

.rotate-45 {
  @apply rotate-[45deg]
}

.rotate-90 {
  @apply rotate-[90deg]
}

.rotate-135 {
  @apply rotate-[135deg]
}

.rotate-180 {
  @apply rotate-[180deg]
}

.rotate-225 {
  @apply rotate-[225deg]
}

.rotate-270 {
  @apply rotate-[270deg]
}

.rotate-315 {
  @apply rotate-[315deg]
}

.rotate-360 {
  @apply rotate-[360deg]
}

.-rotate-45 {
  @apply rotate-[-45deg]
}

.-rotate-90 {
  @apply rotate-[-90deg]
}

.-rotate-135 {
  @apply rotate-[-135deg]
}

.-rotate-180 {
  @apply rotate-[-180deg]
}

.-rotate-225 {
  @apply rotate-[-225deg]
}

.-rotate-270 {
  @apply rotate-[-270deg]
}

.-rotate-315 {
  @apply rotate-[-315deg]
}

.-rotate-360 {
  @apply rotate-[-360deg]
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, white, #bfd8f5);
}




/* Initial state */

/* Classe personnalisée pour les transitions */
.fade-out {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

/* Pour l'affichage */
.fade-in {
  opacity: 1;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}


/* Input field styles */
.input-field {
  
  background-color: #f1f5f9; /* Slate-100 background */
  padding: 0.5rem; /* 8 pixels padding */
  border: 2px solid #bebebe; /* Zinc-500 border */
  box-shadow: 0 1px 1px rgba(115, 115, 115, 0.5); /* Small shadow with Zinc-500 color */
  border-radius: 1rem; /* Rounded corners */
  transition: border-color 0.3s, background-color 0.3s, box-shadow 0.3s; /* Smooth transition */
}

/* Focus styles */
.input-field:focus {
  background-color: #ffffff; /* White background */
  border-color: #0284c7; /* Sky-600 border */
  
  box-shadow: 0 0 5px rgba(191, 219, 254, 1); /* Sky-200 shadow */
  outline: none; /* Remove default outline */
}






